@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0%;
  padding: 0%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #4f61e6;
  border: 0px groove #4f61e6;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #4f61e6;
}

::-webkit-scrollbar-thumb:active {
  background: #007bff;
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #666666;
}

::-webkit-scrollbar-track:active {
  background: #333333;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

body {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
p {
  font-size: 1rem;
  color: #0000008c;
}
/* website btn css start*/
.button {
  background: none;
  border: none;
  cursor: pointer;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.75em 2em;
  letter-spacing: 0.05rem;
  text-decoration: none;
}
.primary-btn {
  background-image: linear-gradient(
    45deg,
    rgba(93, 22, 216, 1) 10%,
    rgba(82, 238, 244, 1) 100%
  );
  border-radius: 0.2rem;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}
.primary-btn:hover {
  font-size: 1.015rem;
  font-weight: 600;
  box-shadow: 6px 6px 12px #14141440;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}
.primary-btn:active {
  font-size: 1rem;
  box-shadow: 0 0 6px 0 #14141440;
  transition: all 0.3s ease-in-out;
}
/* website btn css end*/
/* header start */

header {
  position: fixed;
  z-index: 9;
  top: 0;
  width: 100%;
  box-shadow: 0 0 1rem rgba(131, 131, 131, 0.15);
}

.logo {
  max-height: 2.5rem;
}

.nav-item {
  padding: 0rem 0.5rem;
}

.nav-link {
  font-weight: 500;
  cursor: pointer;
}

.nav-link:hover {
  color: #1b0f61;
}
header .navbar-toggler {
  display: flex;
  flex-flow: column;
  border: none;
  opacity: 0;
  transition: all 0.3s linear;
}
header .navbar-toggler:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
header .navbar-toggler span {
  display: inline-flex;
  flex-flow: column;
  width: 1.5rem;
  height: 0.1rem;
  margin: 0.2rem;
  border-radius: 0.5rem;
  background: #212529;
}
.toggle_anime span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 6px);
  height: 0.15rem;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.toggle_anime span:nth-child(2) {
  opacity: 0;
}
.toggle_anime span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -6px);
  height: 0.15rem;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes navlinkfade {
  0% {
    opacity: 0;
    transform: skew(50px);
  }
  100% {
    opacity: 1;
    transform: skew(0px);
  }
}
/* header end */
/* banner section start */

.banner-section {
  background-image: url(../images/header-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100vh;
}

.banner-title {
  animation: banner-title 0.8s linear;
}

@keyframes banner-title {
  0% {
    opacity: 0;
    transform: translate3d(-50px, 0px, 0px);
  }

  100% {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
}

.banner-title h1 {
  font-size: 3rem;
  font-weight: 600;
}

.banner-title p {
  font-size: 1.5rem;
  font-weight: 300;
}

.banner-img {
  animation: banner-img 0.8s linear;
}

@keyframes banner-img {
  0% {
    opacity: 0;
    transform: translate3d(0px, -50px, 0px);
  }

  100% {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px);
  }
}

/* hero section end */

.section {
  padding: 4rem 0rem 0rem 0rem;
}

.section-tag {
  font-weight: 600;
  color: #212529;
}

.heading h2 {
  color: #1b0f61;
  font-size: 2.5rem;
  font-weight: 600;
}

.heading p {
  color: #000000;
  font-size: 2.25rem;
  font-weight: 300;
}

/* about section start */
.work-flow {
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 2px 2px 6px rgba(131, 131, 131, 0.15);
  transition: all 0.4s linear;
}
.work-flow:hover {
  background-color: #173eae;
  color: #ffffff;
}
.work-flow:hover p {
  color: #ffffff;
}
.work-flow:hover .work-flow-img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(106deg)
    brightness(200%) contrast(180%);
}

/* about section end */

/* service section start */
.service-img {
  width: 70%;
}

.service-description {
  letter-spacing: 1px;
}

.carousel-bg {
  background: url(../images/ban21.png) left top no-repeat;
  background-size: cover;
}

.slide-bg {
  background: url(../images/slider-bg.png) right top no-repeat;
  background-size: cover;
}

.carousel-position {
  width: min-content;
  overflow: hidden;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slide-img {
  background: #ffffff;
  padding: 1rem;
  margin: 1rem;
  height: 100px;
  box-shadow: 6px 6px 6px rgba(131, 131, 131, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-img img {
  filter: invert(27%) sepia(77%) saturate(0%) hue-rotate(179deg) brightness(97%)
    contrast(97%);
}

.slide-img:hover img {
  filter: invert(51%) sepia(69%) saturate(2484%) hue-rotate(210deg)
    brightness(94%) contrast(85%);
}

/* service section end */

/* team section start */
.team-bg {
  background: linear-gradient(
    160deg,
    rgba(101, 84, 179, 1) 0%,
    rgba(147, 115, 191, 1) 100%
  );
}

.team-member-card {
  background-color: #fff0;
  border: none;
}

.card-img {
  border-radius: inherit;
  filter: grayscale(1);
}

.team-member-card:hover .card-img {
  background: #f2f2f2;
  filter: none;
  transition: all 0.3s linear;
}

.team-member-detail {
  color: #ffffff;
}
.team-member-detail .card-title {
  color: #ffffff;
  transition: all 0.3s linear;
}
.team-member-detail .card-text {
  color: #ffffff;
  font-weight: 500;
  transition: all 0.3s linear;
}

.team-member-card:hover .team-member-detail {
  background: #f2f2f2;
  color: #1b0f61;
  transition: all 0.3s linear;
}
.team-member-card:hover .card-title,
.team-member-card:hover .card-text {
  color: #1b0f61;
  transition: all 0.3s linear;
}

/* team section end */
/* Portfolio Section start  */
.portfolio-content {
  border-radius: 0 0 8px 8px;
  background: #f7f7f7;
  overflow: hidden;
  transition: all 1.2s cubic-bezier(0.17, 0.85, 0.438, 0.99);
}
.card-title {
  text-decoration: none;
  font-size: 1.125rem;
  font-weight: 600;
  color: #212529;
  transition: all 1.2s cubic-bezier(0.17, 0.85, 0.438, 0.99);
}
.portfolio-img {
  transition: all 1.2s cubic-bezier(0.17, 0.85, 0.438, 0.99);
}
.portfolio-content:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.portfolio-content:hover .card-title {
  color: #000080;
  transition: all 1.2s cubic-bezier(0.17, 0.85, 0.438, 0.99);
}
.portfolio-content:hover .portfolio-img {
  transform: scale(1.05);
  transition: all 1.2s cubic-bezier(0.17, 0.85, 0.438, 0.99);
}
/* Portfolio Section end  */
/* contact section start */
.form-bg {
  background: linear-gradient(180deg, #b993d6 41.57%, #5243a5 100%);
  color: #fff;
}

.form-box {
  background: rgba(48, 48, 48, 0.15);
}

.form-control:focus {
  background: rgba(255, 255, 255, 0.05) !important;
  box-shadow: 0.02rem 0.02rem 0.2rem #9376b5 !important;
}

.form-box input::placeholder {
  color: white;
}

.form-box textarea::placeholder {
  color: #fff;
}

.submit-btn {
  padding: 0.5em 2em;
  background: transparent;
  color: #ffffff;
  border: 0.125rem solid #ffffff;
  border-radius: 0.3rem;
}
.submit-btn:hover {
  box-shadow: 0 0 0.75rem #808080;
}
.submit-btn:active {
  box-shadow: inset 0 0 0.75rem #808080;
}
/* contact section end */
/* footer start */
.footer-bg {
  background: url(../images/header-bg.jpg) center center repeat;
}

.footer-content {
  padding: 6rem 0rem 0rem 0rem;
}
.contact a {
  color: #ffffff;
}
.contact a:hover {
  color: #cccccc;
  transition: all 0.3s;
}
.social-media-link img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(215deg)
    brightness(102%) contrast(103%);
  padding: 0.5rem;
}

.social-media-link:hover img {
  filter: invert(73%) sepia(51%) saturate(404%) hue-rotate(148deg)
    brightness(99%) contrast(92%);
}
.footer-link {
  color: #f7f7f7;
}
.footer-link:hover {
  color: #cccccc;
}
.hr-line {
  height: 1rem;
  width: 50%;
  background-color: #fff;
}
/* footer end */
/* Error page css */
.error-section {
  background: #f4f9ff;
  height: 100vh;
  padding: 2rem 0;
}
.error-btn {
  background: linear-gradient(
    45deg,
    rgba(93, 22, 216, 1) 20%,
    rgba(82, 238, 244, 1) 100%
  );
  color: #ffffff;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  display: flex;
  align-items: center;
  border-radius: 0.3rem;
  padding: 0.5rem 1.5rem;
  max-width: max-content;
  transition: all 0.5s cubic-bezier(0.17, 0.85, 0.438, 0.99);
}
.error-btn::before {
  content: "\00AB";
  font-size: 1.5rem;
  transform: translateX(-0.3rem);
  transition: all 0.5s cubic-bezier(0.17, 0.85, 0.438, 0.99);
}
.error-btn:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  background: linear-gradient(
    215deg,
    rgba(93, 22, 216, 1) 20%,
    rgba(82, 238, 244, 1) 100%
  );
  color: #ffffff;
  transition: all 0.5s cubic-bezier(0.17, 0.85, 0.438, 0.99);
}
.error-btn:hover::before {
  transform: translateX(-0.6rem);
}
.error-btn:active {
  background: linear-gradient(
    45deg,
    rgba(93, 22, 216, 1) 20%,
    rgba(82, 238, 244, 1) 100%
  );
  box-shadow: 0px 0px 3px 1px #dddddd;
}
.error-btn:active::before {
  transform: translateX(-1.2rem);
}
.error_img img {
  text-shadow: 0 0 6px #212529;
}
.cursor-pointer {
  cursor: pointer;
}
.custom_btn{
border-color: #d7d7d7;
color: #818181;
}
.custom_btn:hover{
  background: #1b0f61;
  color: #ffffff;
}
/* Responsive Css  */
@media screen and (max-width: 992px) {
  header .navbar-toggler {
    opacity: 1;
  }
  body .header .navbar-collapse .navbar-nav .nav-item .nav-link {
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
  }
  .slide-bg {
    background: url(../images/white-bg.png);
    background-size: cover;
  }
}
@media screen and (max-width: 765px) {
  .banner-section {
    height: auto;
  }
  .banner-title {
    margin-top: 2rem;
  }
}
/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  /* background-color: #25d366; */
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  /* box-shadow: 2px 2px 3px #999; */
  z-index: 100;
}

.whatsapp-icon {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}
